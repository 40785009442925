class Elementor_Products_Tabs {
    static instance;

    static getInstance() {
        if (!Elementor_Products_Tabs.instance) {
            Elementor_Products_Tabs.instance = new Elementor_Products_Tabs();
        }
        return Elementor_Products_Tabs.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-products-tabs.default', ($scope) => {
            $scope.addClass('elementor-widget-tabs');
            let $tabs = $scope.find('.elementor-tabs');
            let $contents = $scope.find('.elementor-tabs-content-wrapper');

            // Active tab
            $contents.find('.elementor-active').show();

            $tabs.find('.elementor-tab-title').on('click', function () {
                $tabs.find('.elementor-tab-title').removeClass('elementor-active');
                $contents.find('.elementor-tab-content').removeClass('elementor-active').hide();
                $(this).addClass('elementor-active');
                let id = $(this).attr('aria-controls');
                $contents.find('#' + id).addClass('elementor-active').show();
            })
            let $carousel = $('.woocommerce-carousel', $scope);
            if ($carousel.length > 0) {
                let data = $carousel.data('settings');
                $('ul.products', $carousel).owlCarousel(Elementor_Carousel.setupData(data));
            }
        });
    }
}

Elementor_Products_Tabs.getInstance();