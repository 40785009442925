class Elementor_Testimonial_Slick {
    static instance;

    static getInstance() {
        if (!Elementor_Testimonial_Slick.instance) {
            Elementor_Testimonial_Slick.instance = new Elementor_Testimonial_Slick();
        }
        return Elementor_Testimonial_Slick.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-testimonials-slick.default', ($scope) => {
            let $carousel = $('.osf-carousel', $scope);
            if ($carousel.length > 0) {
                if ($carousel.hasClass('layout-style-2')) {
                    let $nav = $('.testimonial-image-style', $scope);
                    $carousel.slick({
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                        fade: true,
                        asNavFor: $nav
                    });
                    $nav.slick({
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        asNavFor: $carousel,
                        adaptiveHeight: false,
                        dots: false,
                        centerMode: true,
                        focusOnSelect: true,
                        arrows: true,
                    });
                } else {
                    let data = $carousel.data('settings');
                    $carousel.slick(
                        {
                            dots: data.dots,
                            arrows: data.arrows,
                            infinite: data.loop,
                            speed: 300,
                            slidesToShow: parseInt(data.items),
                            autoplay: data.autoplay,
                            autoplaySpeed: data.autoplaySpeed,
                            slidesToScroll: 1,
                            lazyLoad: 'ondemand',
                            rtl: data.rtl,
                            responsive: [
                                {
                                    breakpoint: 1024,
                                    settings: {
                                        slidesToShow: parseInt(data.items_tablet),
                                    }
                                },
                                {
                                    breakpoint: 768,
                                    settings: {
                                        slidesToShow: parseInt(data.items_mobile),
                                    }
                                }
                            ]
                        }
                    );
                }
            }

        });
    }


}


Elementor_Testimonial_Slick.getInstance();