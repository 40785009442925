class Elementor_Opal_Product_Categories {
    static instance;

    static getInstance() {
        if (!Elementor_Opal_Product_Categories.instance) {
            Elementor_Opal_Product_Categories.instance = new Elementor_Opal_Product_Categories();
        }
        return Elementor_Opal_Product_Categories.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-product-categories.default', ($scope) => {
            let $carousel = $scope.find('.owl-carousel');
            if ($carousel.length > 0) {
                let data = $carousel.data('settings');
                $carousel.owlCarousel(Elementor_Carousel.setupData(data));
            }

        });
    }
}


Elementor_Opal_Product_Categories.getInstance();